import React, { useState } from "react";
import "./faq.scss";

const Faq = () => {
  const [active, setActive] = useState(null);

  const faqs = [
    {
      question: "How much do I have to pay for STS?",
      answer:
        "Segsalerty Talent Support Initiative is a completely free but highly competitive support foundation. You don’t pay anything to apply or to join a cohort once selected.",
    },
    {
      question: "What do I need to do to qualify for STS?",
      answer:
        "You must possess at least a skill in our development stack. Please note that we do not train you on any of these skills, we only provide the environment for you to nurture your skills, work on projects and build your portfolio.",
    },
    {
      question: "How long does STS training last?",
      answer:
        "As earlier mentioned, we do not offer any form of training. Once admitted, fellows have the opportunity to use the STS space and all its benefits to grow based on their own personal development plan.",
    },
    {
      question: "Does Segsalerty promise job opportunities?",
      answer:
        "No. We will support you in your job hunting and interview preparation processes, however, we do not offer a guaranteed job placement.",
    },
    {
      question:
        "What tech skills do I need to possess to be a member of the community?",
      answer:
        "Irrespective of the tech skill you possess, you will find your tribe within our community.",
    },
  ];
  return (
    <section className="faq_Section section_with_subSections desktopMaxWidth">
      <h5 className="sectionLabel">Faq</h5>

      <div className="processWrapper should_stack">
        <div className="contentWrapper">
          <h4>
            Frequently asked <span>questions</span>
          </h4>
          <p>
            Don’t take our words for it directly. See what our students have to
            say about their experience with segsalerty resources and talent
            support.
          </p>
        </div>

        <div className="questionsWrapper">
          {faqs.map((item, index) => (
            <div className="faq-item" key={index}>
              <div className="question">
                <p>{item.question}</p>

                <span
                  onClick={() => {
                    setActive(index);
                  }}
                >
                  <svg
                    width="17"
                    height="16"
                    viewBox="0 0 17 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M15.5 7H9.5V1C9.5 0.734784 9.39464 0.48043 9.20711 0.292893C9.01957 0.105357 8.76522 0 8.5 0C8.23478 0 7.98043 0.105357 7.79289 0.292893C7.60536 0.48043 7.5 0.734784 7.5 1V7H1.5C1.23478 7 0.98043 7.10536 0.792893 7.29289C0.605357 7.48043 0.5 7.73478 0.5 8C0.5 8.26522 0.605357 8.51957 0.792893 8.70711C0.98043 8.89464 1.23478 9 1.5 9H7.5V15C7.5 15.2652 7.60536 15.5196 7.79289 15.7071C7.98043 15.8946 8.23478 16 8.5 16C8.76522 16 9.01957 15.8946 9.20711 15.7071C9.39464 15.5196 9.5 15.2652 9.5 15V9H15.5C15.7652 9 16.0196 8.89464 16.2071 8.70711C16.3946 8.51957 16.5 8.26522 16.5 8C16.5 7.73478 16.3946 7.48043 16.2071 7.29289C16.0196 7.10536 15.7652 7 15.5 7Z"
                      fill="white"
                    />
                  </svg>
                </span>
              </div>

              <div className={`answer ${active === index ? "active" : ""}`}>
                {item.answer}
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Faq;
