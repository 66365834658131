import { NavLink } from "react-router-dom";
import "./header.scss";
import headerLogo from "../../images/brandAssets/logoHeader.svg";
import { ReactComponent as ArrowRight } from "../../images/arrow-right.svg";

const Header = ({ flip, menuFlip, reset }) => {
  const routes = [
    {
      name: "Home",
      path: "/",
    },
    {
      name: "STS",
      path: "/sts",
    },
    {
      name: "Hire Us",
      path: "/hire-us",
    },
    {
      name: "Events",
      path: "/events",
    },
    {
      name: "About Us",
      path: "/about-us",
    },
  ];

  let appLink =
    "https://docs.google.com/forms/d/e/1FAIpQLSd78J8pznhI-9j2hqoJ43q98n7rjrV6UqGrFsM-11emlNB4Ig/viewform";

  return (
    <div>
      <div className="call2action">
        <div>
          🎉 Registration for Segsalerty talent support is on till 30th April,
          2023.
          <span>
            <a target="_blank" rel="noreferrer" href={appLink}>
              Apply now
            </a>
          </span>
          <ArrowRight />
        </div>
      </div>

      <header className="header desktopMaxWidth">
        <div className="logo">
          <NavLink onClick={reset} to="/">
            <img src={headerLogo} alt="" />
          </NavLink>
        </div>

        <nav
          className={
            flip
              ? "header_nav header_navMobile active"
              : "header_nav header_navMobile"
          }
        >
          <ul>
            {routes.map((item, index) => (
              <li key={index}>
                <NavLink onClick={reset} to={item.path}>
                  {item.name}
                </NavLink>
              </li>
            ))}
          </ul>

          <NavLink onClick={reset} to="/">
            Donate
          </NavLink>
        </nav>

        <div onClick={menuFlip} className="menu">
          <div className={flip ? "hambuger active" : "hambuger"}>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
      </header>
    </div>
  );
};

export default Header;
