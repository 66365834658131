import React from "react";
import { Link } from "react-router-dom";
import "./footer.scss";
import footerLogo from "../../images/brandAssets/logoFooter.svg";

import twitterIcon from "../../images/brandAssets/socialMediaIcons/twitter.svg";
// import discordIcon from "../../images/brandAssets/socialMediaIcons/discord.svg";
import linkedInIcon from "../../images/brandAssets/socialMediaIcons/linkedIn.svg";
import facebookIcon from "../../images/brandAssets/socialMediaIcons/facebook.svg";
// import youtubeIcon from "../../images/brandAssets/socialMediaIcons/youtube.svg";
import instagramIcon from "../../images/brandAssets/socialMediaIcons/instagram.svg";

let socialLinks = [
  {
    icon: twitterIcon,
    url: "https://twitter.com/SegsalertyTS",
  },
  {
    icon: linkedInIcon,
    url: "https://www.linkedin.com/company/segsalerty-resources/",
  },
  {
    icon: facebookIcon,
    url: "",
  },
  {
    icon: instagramIcon,
    url: "",
  },
];

const SocialIconClass = ({ url, image }) => (
  <a href={url} target="_blank" rel="noreferrer">
    <img src={image} alt="" />
  </a>
);

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footerWrapper">
        <img src={footerLogo} alt="" />

        <div className="footer_links_cta">
          <form className="footer_form">
            <h3>Join the community</h3>
            <span>Get Access to our equipped state-of-the-art facility</span>
            <div className="cta">
              <input
                type="text"
                id="search"
                name="search"
                placeholder="Enter email address"
              />
              <button type="submit">Join the network</button>
            </div>
          </form>

          <nav className="footer_nav">
            <div>
              <h3>Branches</h3>
              <ul>
                <li>
                  <Link to="/">STS</Link>
                </li>
                <li>
                  <Link to="/">SRS</Link>
                </li>
              </ul>
            </div>

            <div>
              <h3>Company</h3>
              <ul>
                <li>
                  <Link to="/">Our Mission</Link>
                </li>
                <li>
                  <Link to="/">Our Vision</Link>
                </li>
                <li>
                  <Link to="/">About Us</Link>
                </li>
              </ul>
            </div>

            <div>
              <h3>Support</h3>
              <ul>
                <li>
                  <Link to="/">Become a Partner</Link>
                </li>
                <li>
                  <Link to="/">Become a Client</Link>
                </li>
                <li>
                  <Link to="/">Buy us coffee</Link>
                </li>
              </ul>
            </div>
          </nav>
        </div>
      </div>

      <div className="footer_copyRight_socialLinks">
        <span>
          © Segsalerty {new Date().getFullYear()}. All rights reserved.
        </span>

        <div>
          {socialLinks.map((item, index) => (
            <SocialIconClass key={index} image={item.icon} url={item.url} />
          ))}
        </div>
      </div>
    </footer>
  );
};

export default Footer;
